<template>
  <div class="container-fluid mt--6">
    <div class="row">
      <div class="col-xl-12">
        <div class="card mb-3">
          <div class="card-header position-relative">
              <h3 class="mb-0">Device List</h3>
              <div class="position-absolute top-2 right-2 pt-1 pr-1">
                <button type="button" class="btn btn-success btn-block" v-if="tableData.length === 0" @click="addDevice()">Add Device</button>
              </div>
            </div>

          <div class="card-body" v-loading="loading">
            <el-table :data="tableData" border style="width: 100%" size="mini">
                  <!-- <el-table-column prop="plusuid" label="Plus UID" width="180"></el-table-column> -->
                  <el-table-column prop="devicename" label="Device Name" align="center" width="120"></el-table-column>
                  <el-table-column prop="os" label="OS" align="center" width="120"></el-table-column>
                  <el-table-column prop="brand" label="Brand" align="center" width="120"></el-table-column>
                  <el-table-column prop="status" label="Status" align="center" width="120"></el-table-column>
                  <el-table-column prop="isPrimary" label="Primary" align="center" width="120"></el-table-column>
                  <el-table-column prop="createdDate" label="Created" align="center" width="120"></el-table-column>
                  <el-table-column prop="updatedDate" label="Updated" align="center" width="120"></el-table-column>
                  <el-table-column prop="plusuid" label="Action" align="center" width="100">
                    <template slot-scope="scope">
                      <el-button type="danger" size="mini" @click="removeDevice(scope.row)">Remove</el-button>
                    </template>
                  </el-table-column>
                </el-table>
          </div>
        </div>
      </div>

      <el-dialog 
      title="Add Device" 
      :visible.sync="dialogAddDevice" 
      width="60%"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="dialogClose">
      
        <div class="text-lg text-center">
          1. Just login to your Online banking App.<br>
          2. Use Device manage button to open Camera QR code scanner.<br>
         3. Scan and success linking device.
        </div>

        <img style="width:50%" class="img-center" src="img/modal/mobile-register-scan.jpg" alt="">

         <qr-code v-if="plusuid" :text="qrcode" :size="150" class="qrcode"></qr-code>

         <div class="mt-4">
           <p class="text-md text-center">
             Please use you mobile device you want to link to your account to scan this QR code
             {{ this.token }}
           </p>
         </div>
  
      </el-dialog>


    </div>

  </div>
</template>

<script>
import deviceApi from '@/api/device'
import Utils from '@/utils'
export default {
  computed:{
    plusuid(){
      return this.$store.getters.plusuid
    }
  },
  data() {
    return {
      loading: false,
      dialogAddDevice: false,
      tableData: [],
      qrcode: "",
      token: "",
    }
  },
  methods:{
    getDeviceList(){
      this.loading = true
      deviceApi.getDeviceList().then(({result, data})=>{
        this.loading = false
        if(result){
          this.tableData = data
          if(data.length === 0){
            this.addDevice()
          }
        }
      })
    },
    addDevice(){
      this.dialogAddDevice = true
      this.socketSubscription()
      this.token = Utils.uuid()
      this.qrcode = `${this.plusuid}:${this.token}`
    },
    dialogClose(){
      this.dialogAddDevice = false
    },

    removeDeviceApi(data){
      deviceApi.removeDevice({uid: data.uid}).then(({result, data})=>{
        if(result && data.scapending){
          this.socketRemoveDeviceSubscription()
        }
      })
    },

    removeDevice(data){
      this.$swal({
          title: 'Confirmation',
          html: `This will Remove Device name: ${data.devicename} OS. ${data.os} Brand: ${data.brand}. Continue?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "Comfirm",
          cancelButtonText: 'Cancel',
          showCloseButton: true,
          reverseButtons: true
        }).then((result) => {
          if(result.value) {
            this.removeDeviceApi(data)
          } else {
            this.$message(`Action Canceled`); 
          }
        })
    },
    socketRemoveDeviceSubscription(){
      this.$socket.connect()
      this.$swalTimer('Remove Pending', 'Please confirm remove device from your mobile device', 'warning')
      this.$swal.showLoading();
      this.sockets.subscribe('remove-device:'+ this.plusuid, (response)=> {
        this.$swal.close()
        this.$socket.disconnect()

          if(response.result){
            this.$swal.fire({
              icon: 'success',
              title: 'Remove Device is success',
              showConfirmButton: false
            })
            setTimeout(() => {
              this.$swal.close()
              this.getDeviceList()
            }, 2000);
          }else{
            this.$swal('Message', response.message, 'error')
          }
        })
      
    },
    socketSubscription(){
      this.$socket.connect()
      this.sockets.subscribe('register-device:'+ this.plusuid, function(data) {
        this.$socket.disconnect()
          if(data.result){
            this.dialogAddDevice = false
            this.getDeviceList()
          }else{
            this.dialogAddDevice = false
            this.$swal('Message', data.message, 'error')
          }
      })
      
    },

  },

  created(){
    this.getDeviceList()
  }
}
</script>

<style>

</style>